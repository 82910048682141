import IconUpload from '@public/icons/Upload';
import { message, Upload } from 'antd';
import { ErrorMessage, Field, FieldInputProps, FormikProps } from 'formik';
import { FC } from 'react';

const UploadComponent: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label: string;
  props: any;
}> = ({ label, ...props }) => {
  const uploadImage = async (options) => {
    const { onSuccess } = options;
    onSuccess('Ok');
  };

  return (
    <div className="upload">
      <Upload customRequest={uploadImage} maxCount={1} {...props}>
        {label}
      </Upload>
    </div>
  );
};

const UploadDraggableComponent: FC<{
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label: string;
  required: boolean;
}> = ({ field, form, label, required, ...props }) => {
  const { Dragger } = Upload;
  const { setFieldValue } = form;
  const { name } = field;

  const handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') {
      setFieldValue(name, info.file.originFileObj);
    } else if (status === 'error') {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const handleDrop = (e) => {
    console.log('Dropped files', e.dataTransfer.files);
  };

  const uploadImage = async (options) => {
    const { onSuccess } = options;
    onSuccess('Ok');
  };

  const handleRemove = () => {
    setFieldValue(name, null);
  };

  return (
    <div className="input-container upload-dragger">
      <div className="label">
        {label}
        {required && '*'}
      </div>
      <Dragger
        customRequest={uploadImage}
        multiple={false}
        maxCount={1}
        onChange={handleChange}
        onDrop={handleDrop}
        onRemove={handleRemove}
        {...props}
      >
        <p className="ant-upload-drag-icon">
          <IconUpload />
        </p>
        <p className="ant-upload-text">
          Drag & Drop file or Choose file to upload
        </p>
      </Dragger>
    </div>
  );
};

export const FieldUpload: FC<{
  name: string;
  label?: string;
  onChange?: any;
  draggable?: boolean;
  required?: boolean;
  value: any;
}> = ({
  name,
  label,
  draggable = false,
  required = false,
  value,
  ...propsField
}) => {
  const Component = draggable ? UploadDraggableComponent : UploadComponent;

  const handleBeforeUpload = (file) => {
    const isAllowFileType = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/pdf',
    ].includes(file.type);

    const isAllowFileSize = file.size / 1024 / 1024 < 10;

    if (!isAllowFileType) {
      message.error('Invalid file format');
    } else if (!isAllowFileSize) {
      message.error('File size can not exceed 10MB');
    }

    return (isAllowFileType && isAllowFileSize) || Upload.LIST_IGNORE;
  };

  const validateField = () => {
    if (!value && required) {
      return 'Field is required';
    }
  };

  return (
    <>
      <Field
        name={name}
        label={label}
        required={required}
        validate={validateField}
        beforeUpload={handleBeforeUpload}
        component={Component}
        {...propsField}
      />
      <ErrorMessage name={name} component="div" className="text-error" />
    </>
  );
};
