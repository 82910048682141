import { Col, Divider, message, Modal, Row, Tabs } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { FieldTextInput } from '@components/FormItem/Input';
import { ButtonPrimary } from '@components/Button';
import { FieldUpload } from '@components/FormItem/Upload';
import { IconExit } from '@public/icons';
import { useModalComplete } from '@context/provider/modal';
import { activeModalComplete, deactivateModal } from '@context/action/modal';
import { contactAPI } from '@apiClient/post-api';

const { TabPane } = Tabs;

const ModalApply = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const { status, tab, dispatch } = useModalComplete();

  const [activeTab, setActiveTab] = useState('1');

  const [loading, setLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const formikRef = useRef(null);

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleApplyNow = (id, dynamicAction) => async (data) => {
    setLoading(true);
    const token = await executeRecaptcha(dynamicAction);

    data = {
      ...data,
      url: `${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`,
      _wpcf7_recaptcha_response: token,
    };

    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    try {
      contactAPI
        .submitForm(id, formData)
        .then((response) => {
          setLoading(false);

          if (response?.data?.status !== 'mail_sent') {
            message.error(
              response?.data?.message || 'Apply for the job unsuccesfully',
            );
            return;
          }

          dispatch(activeModalComplete());
          formikRef.current.resetForm();
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <Modal
      visible={status}
      className="modal-apply"
      width={1600}
      footer={null}
      closeIcon={<IconExit />}
      onCancel={() => {
        dispatch(deactivateModal());
      }}
    >
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        <TabPane tab="Apply now" key={1}>
          <Formik
            initialValues={{
              username: '',
              email: '',
              phone: '',
              portfolio: '',
              file: null,
            }}
            innerRef={formikRef}
            onSubmit={handleApplyNow(11400, 'applyJob')}
          >
            {({ values }) => (
              <Form className="form-apply">
                <Row gutter={[28, 24]}>
                  <Col xs={24} md={11}>
                    <FieldTextInput
                      name="username"
                      label={t('home.textYourName')}
                      required
                      value={values.username}
                    />
                    <FieldTextInput
                      name="email"
                      label={t('home.textEmailAddress')}
                      required
                      value={values.email}
                    />
                    <FieldTextInput
                      name="phone"
                      label="Phone number"
                      required
                      value={values.phone}
                    />
                  </Col>
                  <Col xs={24} md={2}>
                    <Divider type="vertical" />
                  </Col>
                  <Col xs={24} md={11}>
                    <FieldTextInput
                      name="portfolio"
                      label="Portfolio"
                      placeholder="Portfolio URL"
                      value={values.portfolio}
                    />
                    <FieldUpload
                      name="file"
                      label="CV"
                      draggable
                      required
                      value={values.file}
                    />
                  </Col>
                </Row>
                <div className="form-apply__action">
                  <div className="action-description">
                    By submitting a CV, you agree with our privacy statements
                  </div>
                  <ButtonPrimary
                    htmlType="submit"
                    className="button-apply"
                    loading={loading}
                  >
                    Apply now
                  </ButtonPrimary>
                </div>
              </Form>
            )}
          </Formik>
        </TabPane>
        <TabPane tab="Refer a friend" key={2}>
          <Formik
            initialValues={{
              candidateName: '',
              candidateEmail: '',
              candidatePhone: '',
              candidatePortfolio: '',
              candidateFile: null,
              referName: '',
              referEmail: '',
              referPhone: '',
            }}
            onSubmit={handleApplyNow(11486, 'referCandidate')}
          >
            {({ values }) => (
              <Form className="form-apply">
                <Row gutter={[28, 24]}>
                  <Col xs={24} md={11}>
                    <div className="column-title">CANDIDATE INFORMATION</div>
                    <FieldTextInput
                      name="candidateName"
                      label="Candidate name"
                      required
                      value={values.candidateName}
                    />
                    <FieldTextInput
                      name="candidateEmail"
                      label="Email address"
                      required
                      value={values.candidateEmail}
                    />
                    <FieldTextInput
                      name="candidatePhone"
                      label="Phone number"
                      value={values.candidatePhone}
                    />
                  </Col>
                  <Col xs={24} md={2}>
                    <Divider type="vertical" />
                  </Col>
                  <Col xs={24} md={11}>
                    <div className="column-title">REFERRER INFORMATION</div>
                    <FieldTextInput
                      name="referName"
                      label="Your name"
                      required
                      value={values.referName}
                    />
                    <FieldTextInput
                      name="referEmail"
                      label="Email address"
                      required
                      value={values.referEmail}
                    />
                    <FieldTextInput
                      name="referPhone"
                      label="Phone number"
                      value={values.referPhone}
                    />
                  </Col>
                  <Col xs={24} md={11}>
                    <FieldTextInput
                      name="candidatePortfolio"
                      label="Portfolio"
                      value={values.candidatePortfolio}
                    />
                    <FieldUpload
                      name="file"
                      label="CV"
                      draggable
                      required
                      value={values.file}
                    />
                  </Col>
                </Row>
                <div className="form-apply__action">
                  <div className="action-description">
                    By submitting a CV, you agree with our privacy statements
                  </div>
                  <ButtonPrimary
                    htmlType="submit"
                    className="button-apply"
                    loading={loading}
                  >
                    Apply now
                  </ButtonPrimary>
                </div>
              </Form>
            )}
          </Formik>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ModalApply;
