import { ModalReducer } from '@context/reducer/modal';
import * as React from 'react';

const INITIAL_STATE = {
  status: false,
  isVisibleModalComplete: false,
};

const ModalContext = React.createContext(INITIAL_STATE) as any;

const ModalProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(ModalReducer, INITIAL_STATE);

  return (
    <ModalContext.Provider
      value={{
        status: state.status,
        tab: state.tab,
        isVisibleModalComplete: state.isVisibleModalComplete,
        dispatch,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalComplete = () => {
  const context = React.useContext(ModalContext) as any;

  if (context === undefined) {
    throw new Error('useModalComplete must be used within a ModalProvider');
  }

  return context;
};

export { ModalProvider, useModalComplete };
