export const ModalReducer = (state, action) => {
  switch (action.type) {
    case 'ACTIVE':
      return {
        status: true,
        tab: action.tab,
      };
    case 'DEACTIVATE':
      return {
        status: false,
      };
    case 'ACTIVE_MODAL_COMPLETE':
      return {
        isVisibleModalComplete: true,
      };
    case 'DEACTIVATE_MODAL_COMPLETE':
      return {
        isVisibleModalComplete: false,
      };
    default:
      return { ...state };
  }
};
