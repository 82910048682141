import axiosClient from './axiois-client';

export const jobAPI = {
  getList(payload) {
    return axiosClient.get('/jobs', payload);
  },
  getTerms(taxonomy) {
    return axiosClient.get('/jobs/terms', {
      params: {
        taxonomy,
      },
    });
  },
};

export const postAPI = {
  getList(payload) {
    return axiosClient.get('/posts', payload);
  },
};

export const contactAPI = {
  submitForm(id: number, payload: any) {
    return axiosClient.post(
      `https://cms.ekoios.vn/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`,
      payload,
    );
  },
};
