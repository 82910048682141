import { Layout, Menu, Grid, Drawer, Collapse } from 'antd';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
// import { SearchOutlined } from '@ant-design/icons';
import { ButtonPrimary } from '@components/Button';
import { IconDot, IconHamburger } from '@public/icons';
import { RightArrowOutline } from '@public/icons';
import classNames from 'classnames';

const { Item } = Menu;
const { Header } = Layout;
const { Panel } = Collapse;

const HeaderComponent = ({ isHeaderLight, menu, ...props }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { pathname } = router;
  const [visible, setVisible] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [itemMenu, setItemMenu] = useState<any>();
  const [keyPanel, setKeyPanel] = useState<Number>();
  const [visibleHeaderTiny, setVisibleHeaderTiny] = useState(false);
  const [activeMenuMobile, setActiveMenuMobile] = useState(true);

  const { useBreakpoint } = Grid;

  const { xl } = useBreakpoint();
  const isMobile = !xl;

  const toggleMenu = () => {
    setVisible((prevState) => !prevState);
    setShowSubMenu(false);
  };

  const handleShowSubMenu = (item) => {
    setItemMenu(item);
    setShowSubMenu(true);
  };

  const linkDirectSubmenu = (element, val) => {
    router.push({
      pathname: val.path + element.link,
    });
    setVisible(false);
    setActiveMenuMobile(true);
  };

  const getKey = (key) => {
    setKeyPanel(+key);
  };

  useEffect(() => {
    const onScroll = () => setVisibleHeaderTiny(window.pageYOffset > 50);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setVisible(false);
      setActiveMenuMobile(true);
    }
  }, [isMobile]);

  return (
    <Header
      className={classNames('header', {
        'header-tiny': visibleHeaderTiny,
        'header-light': isHeaderLight,
      })}
      {...props}
    >
      <div className="container">
        <Link href={'/'}>
          <img
            src={
              isHeaderLight && !visibleHeaderTiny
                ? '/images/logo-light.svg'
                : '/images/logo.svg'
            }
            alt="Ekoios Logo"
            className="header__logo"
          />
        </Link>
        {!isMobile && (
          <>
            <Menu mode="horizontal" className="header__menu">
              {menu.map((item, index) => (
                <Item
                  key={index}
                  className={classNames({
                    'item-active': item.path === pathname,
                    'item-last': index === menu.length - 1,
                  })}
                >
                  <Link href={item.path}>
                    <a target={item?.isExternal ? '_blank' : ''}>
                      {item.title}
                    </a>
                  </Link>
                </Item>
              ))}
            </Menu>
            <div className="header__right">
              {/* <Button icon={<SearchOutlined />} className="button-search" /> */}

              <Link href={`${process.env.NEXT_PUBLIC_MAIN_SITE_URL}/contact`}>
                <a>
                  <ButtonPrimary>{t('common.buttonContactUs')}</ButtonPrimary>
                </a>
              </Link>
            </div>
          </>
        )}
        {isMobile && (
          <div className="btn-menu-mobile">
            <span onClick={toggleMenu}>
              <IconHamburger
                activeMenuMobile={activeMenuMobile}
                setActiveMenuMobile={setActiveMenuMobile}
              />
            </span>
          </div>
        )}
        <Drawer
          placement="right"
          onClose={toggleMenu}
          visible={visible}
          width="100vw"
          closable={false}
          className={classNames('drawer-menu', {
            'drawer-menu-scroll': visibleHeaderTiny,
          })}
        >
          <div className="menu-mobile">
            {!showSubMenu ? (
              <Menu>
                {menu.map((item, idx) => {
                  if (item.subMenu) {
                    return (
                      <div
                        key={idx}
                        className="submenu"
                        onClick={() => handleShowSubMenu(item)}
                      >
                        <div className="name">{item.title}</div>
                        <RightArrowOutline />
                      </div>
                    );
                  } else {
                    return (
                      <div className="link" key={idx}>
                        <Link
                          href={{
                            pathname: item.path,
                          }}
                        >
                          <a
                            onClick={() => {
                              setVisible(false), setActiveMenuMobile(true);
                            }}
                            className={classNames({
                              'menu-active': item.path === pathname,
                            })}
                            target={item?.isExternal ? '_blank' : ''}
                          >
                            <div>{item.title}</div>

                            {item.path === pathname || idx === 0 ? (
                              <img
                                src="/icons/arrow-right-linear-short.svg"
                                alt="arrow right"
                                className="icon-arrow-linear"
                              />
                            ) : (
                              <img
                                src="/icons/arrow-right-black.svg"
                                alt="arrow right"
                              />
                            )}
                          </a>
                        </Link>
                      </div>
                    );
                  }
                })}
              </Menu>
            ) : (
              <div className="show-submenu">
                <div className="top">
                  <div className="back" onClick={() => setShowSubMenu(false)}>
                    <RightArrowOutline className="left-arrow" />
                  </div>
                  <div className="title">{itemMenu && itemMenu.title}</div>
                </div>

                <div className="collapse">
                  <Collapse
                    accordion={true}
                    ghost
                    expandIconPosition="right"
                    destroyInactivePanel={true}
                    expandIcon={({ isActive }) => (
                      <RightArrowOutline
                        className={classNames({ 'down-arrow': isActive })}
                      />
                    )}
                    onChange={getKey}
                  >
                    {itemMenu.subMenu.map((val, idx) =>
                      val?.items ? (
                        <Panel
                          header={
                            <Link
                              href={{
                                pathname: val.path,
                              }}
                            >
                              <a
                                onClick={() => {
                                  setVisible(false), setActiveMenuMobile(true);
                                }}
                                className={classNames({
                                  'color-primary': keyPanel === idx,
                                })}
                              >
                                {val.title}
                              </a>
                            </Link>
                          }
                          key={idx}
                        >
                          {val.items.map((element, key) => (
                            <div
                              key={key}
                              onClick={() => linkDirectSubmenu(element, val)}
                            >
                              <IconDot />
                              <span className="name-sub">{element.name}</span>
                            </div>
                          ))}
                        </Panel>
                      ) : (
                        <Panel
                          header={
                            <Link
                              href={{
                                pathname: val.path,
                                query: val.slug && { name: val.slug, page: 1 },
                              }}
                            >
                              <a
                                onClick={() => {
                                  setVisible(false), setActiveMenuMobile(true);
                                }}
                              >
                                {val.title}
                              </a>
                            </Link>
                          }
                          key={idx}
                          showArrow={false}
                        />
                      ),
                    )}
                  </Collapse>
                </div>
              </div>
            )}
          </div>
          <Link href={`${process.env.NEXT_PUBLIC_MAIN_SITE_URL}/contact`}>
            <a className="section-contact">
              <ButtonPrimary>{t('common.buttonContactUs')}</ButtonPrimary>
            </a>
          </Link>
        </Drawer>
      </div>
    </Header>
  );
};

export default HeaderComponent;
