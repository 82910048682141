import React, { FC, useEffect, useState } from 'react';
import { Grid, Modal } from 'antd';
import { IconComplete, IconExit } from '@public/icons';
import Link from 'next/link';
import { ButtonPrimary } from '@components/Button';
import { useTranslation } from 'next-i18next';
import { useModalComplete } from '@context/provider/modal';
import { deactivateModalComplete } from '@context/action/modal';
import { ModalCompleteType } from '@models/modal';
import classNames from 'classnames';
import { useRouter } from 'next/router';

const { useBreakpoint } = Grid;

const ModalComplete: FC<ModalCompleteType> = ({
  title,
  subtitle,
  buttonProps = { text: '', path: '' },
  className,
}) => {
  const screens = useBreakpoint();
  const [widthModal, setWidthModal] = useState(788);
  const [widthIconComplete, setWidthIconComplete] = useState(84);
  const [heightIconComplete, setHeightIconComplete] = useState(84);
  const { isVisibleModalComplete, dispatch } = useModalComplete();
  const { t } = useTranslation();

  const router = useRouter();

  const isHomepage = router.pathname === '/';

  useEffect(() => {
    if (screens.sm) {
      setWidthModal(608);
      setWidthIconComplete(54);
      setHeightIconComplete(54);
    }
    if (screens.xxl) {
      setWidthModal(788);
      setWidthIconComplete(84);
      setHeightIconComplete(84);
    }
    if (screens.xs) {
      setWidthModal(327);
      setWidthIconComplete(34);
      setHeightIconComplete(34);
    }
  }, [screens]);
  return (
    <div className="modals">
      <Modal
        centered
        visible={isVisibleModalComplete}
        width={widthModal}
        closeIcon={
          <span onClick={() => dispatch(deactivateModalComplete())}>
            <IconExit />
          </span>
        }
        className={classNames('modal-complete', className)}
      >
        <IconComplete
          widthIconComplete={widthIconComplete}
          heightIconComplete={heightIconComplete}
        />
        <div className="title">
          <span>{title || t('home.titleModal')}</span>
        </div>
        <div className="subtitle">
          <span>{subtitle || t('home.subModal')}</span>
        </div>
        {!isHomepage && (
          <div className="button-homepage">
            <Link href={{ pathname: buttonProps.path || '/' }}>
              <a>
                <ButtonPrimary
                  onClick={() => dispatch(deactivateModalComplete())}
                >
                  {buttonProps.text || t('home.txtExit')}
                </ButtonPrimary>
              </a>
            </Link>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalComplete;
